import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Observable, fromEvent } from 'rxjs';

// Define window with gtag
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private platform: Platform
  ) {
    // Wait for platform to be ready before initializing analytics
    this.platform.ready().then(() => {
      // Add Google Analytics script dynamically
      this.addGoogleAnalytics();
      
      // Setup navigation tracking
      this.setupNavigationTracking();
      
      // Track app version and build information
      this.trackAppInfo();
    });
  }
  
  /**
   * Track application version and build information
   */
  private trackAppInfo(): void {
    if (!window.gtag) return;
    
    // Set app information as user properties
    const appInfo = {
      app_version: environment.appVersion,
      build_date: environment.buildDate,
      environment: environment.production ? 'production' : 'development',
      platform: 'web',
      device_type: this.detectDeviceType(),
      ionic_platform: this.platform.platforms().join(',')
    };
    
    // Set as persistent user properties
    window.gtag('set', 'user_properties', appInfo);
    
    // Also track as an event for historical record
    this.trackEvent('app_init', 'system', environment.appVersion, undefined, appInfo);
  }
  
  /**
   * Detect the user's device type
   */
  private detectDeviceType(): string {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return 'mobile';
    }
    return 'desktop';
  }

  /**
   * Add Google Analytics script to the document
   */
  private addGoogleAnalytics(): void {
    if (!environment.googleAnalyticsId) {
      console.warn('Google Analytics ID not set in environment');
      return;
    }
    
    // Check for user consent (simple implementation)
    try {
      // If hasAnalyticsConsent function exists and returns false, don't add GA
      if (window['hasAnalyticsConsent'] && typeof window['hasAnalyticsConsent'] === 'function') {
        if (!window['hasAnalyticsConsent']()) {
          console.info('Analytics disabled by user consent settings');
          return;
        }
      }
    } catch (e) {
      console.warn('Error checking analytics consent, proceeding with analytics', e);
    }

    // Initialize dataLayer if not already initialized
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };
    
    // Initialize gtag
    window.gtag('js', new Date());
    
    // Configure GA with default settings
    // Note: we disable automatic page views since we'll track them manually via router
    window.gtag('config', environment.googleAnalyticsId, {
      send_page_view: false,  // Disable automatic page views
      anonymize_ip: true,     // Enable IP anonymization for GDPR compliance
      cookie_flags: 'SameSite=None;Secure', // Set secure cookies
      cookie_domain: window.location.hostname,
      cookie_expires: 63072000, // 2 years in seconds
      page_title: document.title
    });
    
    // Add GA script to the document
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
    document.head.appendChild(script);
    
    // Log that analytics has been initialized
    console.info('Google Analytics initialized with ID:', environment.googleAnalyticsId);
  }

  /**
   * Track page views on router navigation
   */
  private setupNavigationTracking(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Extract route information
      const { urlAfterRedirects } = event;
      
      // Get page name from URL (remove leading slash and query params)
      let pageName = urlAfterRedirects.split('?')[0];
      if (pageName === '/') {
        pageName = 'home';
      } else {
        // Remove leading slash and convert to path format like 'site/contact'
        pageName = pageName.substring(1).replace(/\//g, '_');
      }
      
      // Get document title with fallback
      const pageTitle = document.title || `Draweb - ${pageName}`;
      
      // Track page view with enhanced information
      this.trackPageView(urlAfterRedirects, pageTitle);
      
      // Also track as a specific page event for better analytics
      this.trackEvent('page_viewed', 'navigation', pageName);
    });
  }

  /**
   * Track a page view
   * @param path URL path viewed
   * @param title Optional page title
   */
  trackPageView(path: string, title?: string): void {
    if (!window.gtag) return;
    
    // Get current language and regional information
    const currentLang = this.translateService.currentLang || 'en';
    
    // Get regional language if available
    let region = '';
    try {
      const savedRegion = localStorage.getItem('regional-language') || '';
      if (savedRegion && savedRegion.includes('-')) {
        region = savedRegion.split('-')[1];
      }
    } catch (e) {
      console.warn('Error accessing localStorage for region:', e);
    }
    
    // Create user properties for better segmentation
    const userProperties = {
      language: currentLang,
      region: region || 'default',
      platform: 'web',
      view_mode: this.detectViewMode(),
      screen_size: this.getScreenSizeCategory()
    };
    
    // Set user properties
    window.gtag('set', 'user_properties', userProperties);
    
    // Send the page_view event with enhanced dimensions
    window.gtag('config', environment.googleAnalyticsId, {
      page_path: path,
      page_title: title || document.title,
      page_location: window.location.href,
    });
  }
  
  /**
   * Detect the current view mode (light/dark)
   */
  private detectViewMode(): string {
    // Check for prefers-color-scheme
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }
    return 'light';
  }
  
  /**
   * Get the screen size category for analytics
   */
  private getScreenSizeCategory(): string {
    const width = window.innerWidth;
    if (width < 576) return 'xs';
    if (width < 768) return 'sm';
    if (width < 992) return 'md';
    if (width < 1200) return 'lg';
    return 'xl';
  }

  /**
   * Track a custom event
   * @param action Event action
   * @param category Event category
   * @param label Optional event label
   * @param value Optional numeric value
   * @param params Additional custom parameters
   */
  trackEvent(
    action: string, 
    category: string, 
    label?: string, 
    value?: number, 
    params?: Record<string, any>
  ): void {
    if (!window.gtag) return;
    
    // Get current language and page information
    const currentLang = this.translateService.currentLang || 'en';
    const currentPath = this.router.url;
    
    // Get regional language if available
    let region = '';
    try {
      const savedRegion = localStorage.getItem('regional-language') || '';
      if (savedRegion && savedRegion.includes('-')) {
        region = savedRegion.split('-')[1];
      }
    } catch (e) {
      console.warn('Error accessing localStorage for region:', e);
    }
    
    // Merge parameters with standard event parameters
    const eventParams = {
      event_category: category,
      event_label: label,
      value: value,
      language: currentLang,
      region: region || 'default',
      page_path: currentPath,
      page_title: document.title,
      screen_size: this.getScreenSizeCategory(),
      timestamp: new Date().toISOString(),
      ...params
    };
    
    // Send the event with all parameters
    window.gtag('event', action, eventParams);
  }

  /**
   * Track form submissions as both event and conversion
   * @param formName Name of the form
   * @param status Status of submission (success/error)
   */
  trackFormSubmission(formName: string, status: 'success' | 'error'): void {
    // Track standard event for form submissions
    this.trackEvent('form_submission', formName, status);
    
    // If successful submission, also track as a conversion for GA4 goals
    if (status === 'success') {
      // Track as a conversion event for GA4
      window.gtag('event', 'conversion', {
        'send_to': environment.googleAnalyticsId,
        'event_category': 'form',
        'event_label': formName,
        'value': 1,
        'non_interaction': false,
        // Additional parameters
        'form_name': formName,
        'form_id': formName.replace(/\s+/g, '_').toLowerCase(),
        'form_success': true,
        'conversion_id': `form_${Date.now()}`
      });
      
      console.info(`Conversion tracked for form: ${formName}`);
    }
  }

  /**
   * Track language change
   * @param language New language code
   * @param previousLanguage Previous language code
   */
  trackLanguageChange(language: string, previousLanguage: string): void {
    this.trackEvent('language_change', 'preferences', `${previousLanguage} -> ${language}`);
  }

  /**
   * Track outbound link clicks
   * @param url URL being navigated to
   * @param linkText Text of the link
   * @param category Optional category for the link (default: 'engagement')
   */
  trackOutboundLink(url: string, linkText: string, category: string = 'engagement'): void {
    // Standard outbound tracking
    this.trackEvent('outbound_link', category, `${linkText}: ${url}`);
    
    // If this is a WhatsApp link, also track as a WhatsApp engagement
    if (url.includes('wa.me') || url.includes('whatsapp')) {
      this.trackWhatsAppClick(url, linkText);
    }
  }
  
  /**
   * Track WhatsApp clicks specifically
   * @param url The WhatsApp URL being opened
   * @param linkText Text associated with the link
   */
  trackWhatsAppClick(url: string, linkText: string): void {
    // Track as a special WhatsApp interaction event
    this.trackEvent('whatsapp_click', 'contact', linkText, undefined, {
      'whatsapp_url': url,
      'is_priority_conversion': true,
      'contact_method': 'whatsapp',
      'conversion_source': this.router.url || 'direct'
    });
    
    // Also track as a conversion for GA4 goals
    window.gtag('event', 'whatsapp_contact', {
      'send_to': environment.googleAnalyticsId,
      'event_category': 'contact',
      'event_label': 'whatsapp_contact',
      'value': 1,
      'non_interaction': false,
      'whatsapp_url': url,
      'conversion_id': `whatsapp_${Date.now()}`
    });
  }

  /**
   * Track social sharing
   * @param platform Social platform (facebook, twitter, etc.)
   * @param contentType Type of content shared
   * @param itemId ID of the shared item
   */
  trackSocialShare(platform: string, contentType: string, itemId: string): void {
    this.trackEvent('social_share', platform, `${contentType}:${itemId}`);
  }

  /**
   * Track content downloads
   * @param fileType Type of file downloaded
   * @param fileName Name of the file
   */
  trackDownload(fileType: string, fileName: string): void {
    this.trackEvent('download', fileType, fileName);
  }

  /**
   * Track video interactions
   * @param videoName Name of the video
   * @param action Action taken (play, pause, complete)
   * @param position Position in the video (percentage)
   */
  trackVideoInteraction(videoName: string, action: 'play' | 'pause' | 'complete', position?: number): void {
    this.trackEvent('video_' + action, 'video', videoName, position);
  }

  /**
   * Track search queries
   * @param query Search query
   * @param resultsCount Number of results
   */
  trackSearch(query: string, resultsCount: number): void {
    this.trackEvent('search', 'site_search', query, resultsCount);
  }

  /**
   * Set user ID for cross-device tracking (when logged in)
   * @param userId User identifier (hashed)
   */
  setUserId(userId: string): void {
    if (!window.gtag) return;
    window.gtag('set', 'user_properties', { user_id: userId });
  }
  
  /**
   * Sets user properties for better segmentation
   * @param properties Properties to set
   */
  setUserProperties(properties: {[key: string]: any}): void {
    if (!window.gtag) return;
    window.gtag('set', 'user_properties', properties);
  }
}