import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, from } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

export interface RegionalLanguage {
  code: string;
  name: string;
  flag: string;
  nativeName: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegionalLanguageService {
  private regions: RegionalLanguage[] = [];
  private readonly indexPath = './assets/i18n/regional/index.json';
  private readonly regionalPath = './assets/i18n/regional/';

  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) {}

  /**
   * Loads the available regions from the index file
   */
  loadAvailableRegions(): Observable<RegionalLanguage[]> {
    return this.http.get<{availableRegions: RegionalLanguage[]}>(this.indexPath).pipe(
      map(response => response.availableRegions),
      tap(regions => this.regions = regions),
      catchError(error => {
        console.error('Error loading regional languages:', error);
        return of([]);
      })
    );
  }

  /**
   * Gets all available regional languages
   */
  getAvailableRegions(): Observable<RegionalLanguage[]> {
    if (this.regions.length > 0) {
      return of(this.regions);
    }
    return this.loadAvailableRegions();
  }

  /**
   * Sets the current language to a regional variant
   * @param regionCode The regional language code (e.g., 'es-AR')
   */
  setRegionalLanguage(regionCode: string): Observable<any> {
    const baseLang = regionCode.split('-')[0];
    
    // First set the base language (e.g., 'es')
    this.translateService.use(baseLang);
    
    // Then load and merge the regional variant
    return this.http.get(`${this.regionalPath}${regionCode}.json`).pipe(
      tap(translations => {
        // Override base translations with regional ones
        for (const key in translations) {
          if (translations.hasOwnProperty(key)) {
            this.translateService.setTranslation(
              baseLang, 
              { [key]: translations[key] }, 
              true // Merge with existing translations
            );
          }
        }
      }),
      catchError(error => {
        console.error(`Error loading regional language ${regionCode}:`, error);
        return of(null);
      })
    );
  }

  /**
   * Gets the current regional language code
   */
  getCurrentRegion(): string {
    // This needs to be stored separately since TranslateService only handles base languages
    return localStorage.getItem('regional-language') || '';
  }

  /**
   * Saves the current regional language code to localStorage
   */
  saveCurrentRegion(regionCode: string): void {
    localStorage.setItem('regional-language', regionCode);
  }

  /**
   * Gets region information by code
   */
  getRegionByCode(regionCode: string): Observable<RegionalLanguage | undefined> {
    return this.getAvailableRegions().pipe(
      map(regions => regions.find(region => region.code === regionCode))
    );
  }
}