import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RegionalLanguageService, RegionalLanguage } from '../../services/regional-language.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  // Base languages
  languages = [
    { code: 'en', name: 'English', flag: '🇺🇸' },
    { code: 'es', name: 'Español', flag: '🇪🇸' }
  ];
  
  // Regional languages (will be loaded from service)
  regionalLanguages: RegionalLanguage[] = [];
  
  isOpen = false;
  showRegionalOptions = false;
  selectedBaseLang = '';

  constructor(
    public translate: TranslateService,
    private regionalLanguageService: RegionalLanguageService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    // Load regional languages
    this.loadRegionalLanguages();
    
    // Get initial language
    const currentLang = this.translate.currentLang || this.translate.defaultLang;
    if (currentLang) {
      this.changeLanguage(currentLang);
    }
    
    // Check if there's a saved regional language
    const savedRegion = this.regionalLanguageService.getCurrentRegion();
    if (savedRegion) {
      this.setRegionalLanguage(savedRegion);
    }
  }
  
  loadRegionalLanguages() {
    this.regionalLanguageService.getAvailableRegions()
      .subscribe(regions => {
        this.regionalLanguages = regions;
      });
  }

  changeLanguage(langCode: string) {
    // Track language change
    const previousLang = this.translate.currentLang || this.translate.getDefaultLang();
    
    this.translate.use(langCode);
    this.selectedBaseLang = langCode;
    
    // Track the language change
    this.analyticsService.trackLanguageChange(langCode, previousLang);
    
    // If this is Spanish, we can show regional options
    this.showRegionalOptions = langCode === 'es';
    
    // Clear any previously saved regional preference
    if (langCode !== 'es') {
      this.regionalLanguageService.saveCurrentRegion('');
    }
  }
  
  setRegionalLanguage(regionCode: string) {
    // Track the region change
    const previousRegion = this.regionalLanguageService.getCurrentRegion() || '';
    
    this.regionalLanguageService.setRegionalLanguage(regionCode)
      .subscribe(() => {
        // Save the preference
        this.regionalLanguageService.saveCurrentRegion(regionCode);
        
        // Track the regional change
        this.analyticsService.trackEvent('region_change', 'preferences', `${previousRegion} -> ${regionCode}`);
      });
  }

  getCurrentLangFlag() {
    // Check if we have a regional language set
    const currentRegion = this.regionalLanguageService.getCurrentRegion();
    if (currentRegion) {
      const region = this.regionalLanguages.find(r => r.code === currentRegion);
      if (region) {
        return region.flag;
      }
    }
    
    // Otherwise, use the base language flag
    const currentLang = this.translate.currentLang || this.translate.getDefaultLang();
    const lang = this.languages.find(l => l.code === currentLang);
    return lang ? lang.flag : '🇺🇸';
  }
  
  getFilteredRegions() {
    return this.regionalLanguages.filter(region => region.code.startsWith(this.selectedBaseLang));
  }
}