import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Observable, fromEvent } from 'rxjs';

// Define window with gtag and Facebook pixel
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
    fbq: any;
    _fbq: any;
    fbPixelId: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private platform: Platform
  ) {
    // Wait for platform to be ready before initializing analytics
    this.platform.ready().then(() => {
      // Add Google Analytics script dynamically
      this.addGoogleAnalytics();
      
      // Add Facebook Pixel
      this.initializeFacebookPixel();
      
      // Setup navigation tracking
      this.setupNavigationTracking();
      
      // Track app version and build information
      this.trackAppInfo();
    });
  }
  
  /**
   * Track application version and build information
   */
  private trackAppInfo(): void {
    if (!window.gtag) return;
    
    // Set app information as user properties
    const appInfo = {
      app_version: environment.appVersion,
      build_date: environment.buildDate,
      environment: environment.production ? 'production' : 'development',
      platform: 'web',
      device_type: this.detectDeviceType(),
      ionic_platform: this.platform.platforms().join(',')
    };
    
    // Set as persistent user properties
    window.gtag('set', 'user_properties', appInfo);
    
    // Also track as an event for historical record
    this.trackEvent('app_init', 'system', environment.appVersion, undefined, appInfo);
  }
  
  /**
   * Detect the user's device type
   */
  private detectDeviceType(): string {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return 'mobile';
    }
    return 'desktop';
  }

  /**
   * Add Google Analytics script to the document
   */
  private addGoogleAnalytics(): void {
    if (!environment.googleAnalyticsId) {
      console.warn('Google Analytics ID not set in environment');
      return;
    }
    
    // Check for user consent (simple implementation)
    try {
      // If hasAnalyticsConsent function exists and returns false, don't add GA
      if (window['hasAnalyticsConsent'] && typeof window['hasAnalyticsConsent'] === 'function') {
        if (!window['hasAnalyticsConsent']()) {
          console.info('Analytics disabled by user consent settings');
          return;
        }
      }
    } catch (e) {
      console.warn('Error checking analytics consent, proceeding with analytics', e);
    }

    // Initialize dataLayer if not already initialized
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };
    
    // Initialize gtag
    window.gtag('js', new Date());
    
    // Configure GA with default settings
    // Note: we disable automatic page views since we'll track them manually via router
    window.gtag('config', environment.googleAnalyticsId, {
      send_page_view: false,  // Disable automatic page views
      anonymize_ip: true,     // Enable IP anonymization for GDPR compliance
      cookie_flags: 'SameSite=None;Secure', // Set secure cookies
      cookie_domain: window.location.hostname,
      cookie_expires: 63072000, // 2 years in seconds
      page_title: document.title
    });
    
    // Add GA script to the document
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
    document.head.appendChild(script);
    
    // Log that analytics has been initialized
    console.info('Google Analytics initialized with ID:', environment.googleAnalyticsId);
  }
  
  /**
   * Initialize Facebook Pixel for remarketing and conversion tracking
   */
  private initializeFacebookPixel(): void {
    if (!environment.facebookPixelId) {
      console.warn('Facebook Pixel ID not set in environment');
      return;
    }
    
    // Check for user consent (simple implementation)
    try {
      // If hasAnalyticsConsent function exists and returns false, don't initialize Facebook Pixel
      if (window['hasAnalyticsConsent'] && typeof window['hasAnalyticsConsent'] === 'function') {
        if (!window['hasAnalyticsConsent']()) {
          console.info('Facebook Pixel disabled by user consent settings');
          return;
        }
      }
    } catch (e) {
      console.warn('Error checking analytics consent, proceeding with Facebook Pixel', e);
    }
    
    // Set the Facebook Pixel ID in the window object
    window.fbPixelId = environment.facebookPixelId;
    
    // Call the initialization function that's defined in index.html
    if (window['initFacebookPixel'] && typeof window['initFacebookPixel'] === 'function') {
      window['initFacebookPixel']();
    } else {
      console.warn('Facebook Pixel initialization function not found');
    }
  }

  /**
   * Track page views on router navigation
   */
  private setupNavigationTracking(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Extract route information
      const { urlAfterRedirects } = event;
      
      // Get page name from URL (remove leading slash and query params)
      let pageName = urlAfterRedirects.split('?')[0];
      if (pageName === '/') {
        pageName = 'home';
      } else {
        // Remove leading slash and convert to path format like 'site/contact'
        pageName = pageName.substring(1).replace(/\//g, '_');
      }
      
      // Get document title with fallback
      const pageTitle = document.title || `Draweb - ${pageName}`;
      
      // Track page view with enhanced information
      this.trackPageView(urlAfterRedirects, pageTitle);
      
      // Also track as a specific page event for better analytics
      this.trackEvent('page_viewed', 'navigation', pageName);
    });
  }

  /**
   * Track a page view
   * @param path URL path viewed
   * @param title Optional page title
   */
  trackPageView(path: string, title?: string, pageData?: any): void {
    // Get current language and regional information
    const currentLang = this.translateService.currentLang || 'en';
    
    // Get regional language if available
    let region = '';
    try {
      const savedRegion = localStorage.getItem('regional-language') || '';
      if (savedRegion && savedRegion.includes('-')) {
        region = savedRegion.split('-')[1];
      }
    } catch (e) {
      console.warn('Error accessing localStorage for region:', e);
    }
    
    // Parse the path to identify the page type
    const pathSegments = path.split('/').filter(segment => segment);
    const pageType = pathSegments[1] || 'home'; // site/[pageType]
    const pageSubtype = pathSegments[2] || ''; // For deeper routes like site/services/[id]
    
    // Extract ID from query parameters if available
    let pageId = '';
    if (path.includes('?id=')) {
      pageId = path.split('?id=')[1].split('&')[0];
    }
    
    // Create standard analytics parameters
    const analyticsParams = {
      page_path: path,
      page_title: title || document.title,
      page_location: window.location.href,
      language: currentLang,
      region: region || 'default',
      platform: 'web',
      view_mode: this.detectViewMode(),
      screen_size: this.getScreenSizeCategory(),
      page_type: pageType,
      page_subtype: pageSubtype,
      page_id: pageId,
      ...pageData // Merge any additional page-specific data
    };
    
    // Google Analytics page view tracking
    if (window.gtag) {
      // Set user properties
      window.gtag('set', 'user_properties', {
        language: currentLang,
        region: region || 'default',
        platform: 'web',
        view_mode: this.detectViewMode(),
        screen_size: this.getScreenSizeCategory()
      });
      
      // Send the page_view event with enhanced dimensions
      window.gtag('config', environment.googleAnalyticsId, {
        page_path: path,
        page_title: title || document.title,
        page_location: window.location.href,
      });
    }
    
    // Facebook Pixel page view tracking with enhanced detail
    if (window.fbq) {
      // Track standard PageView
      window.fbq('track', 'PageView');
      
      // Create Facebook-specific content parameters based on page type
      const fbParams: any = {
        content_name: title || document.title,
        content_type: 'page',
        content_ids: [path],
        content_category: pageType,
      };
      
      // Add page specific data for remarketing
      switch (pageType) {
        case 'products':
          fbParams.content_type = 'product_list';
          if (pageId) {
            fbParams.content_type = 'product';
            fbParams.content_ids = [pageId];
            
            // Add additional product data if available
            if (pageData && pageData.product) {
              const product = pageData.product;
              fbParams.content_name = product.name;
              fbParams.content_category = product.category;
              if (product.price) {
                fbParams.value = this.extractNumericPrice(product.price);
                fbParams.currency = 'USD';
              }
              if (product.tags) {
                fbParams.content_tags = product.tags;
              }
            }
          }
          break;
          
        case 'services':
          fbParams.content_type = 'service_list';
          if (pageId) {
            fbParams.content_type = 'service';
            fbParams.content_ids = [pageId];
            
            // Add additional service data if available
            if (pageData && pageData.service) {
              const service = pageData.service;
              fbParams.content_name = service.title;
              fbParams.content_features = service.features?.join(',');
            }
          }
          break;
          
        case 'contact':
          fbParams.content_type = 'contact_page';
          break;
          
        case 'about':
          fbParams.content_type = 'about_page';
          break;
          
        case 'home':
          fbParams.content_type = 'home_page';
          break;
      }
      
      // Track ViewContent event with enhanced parameters for better remarketing
      window.fbq('track', 'ViewContent', fbParams);
    }
  }
  
  /**
   * Extract numeric price from a price string
   * @param priceString Price string (e.g. "$99.99", "From $50", etc)
   */
  private extractNumericPrice(priceString: string): number {
    if (!priceString) return 0;
    
    // Extract number pattern from string
    const matches = priceString.match(/[0-9]+(\.[0-9]+)?/);
    if (matches && matches[0]) {
      return parseFloat(matches[0]);
    }
    
    return 0;
  }
  
  /**
   * Detect the current view mode (light/dark)
   */
  private detectViewMode(): string {
    // Check for prefers-color-scheme
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }
    return 'light';
  }
  
  /**
   * Get the screen size category for analytics
   */
  private getScreenSizeCategory(): string {
    const width = window.innerWidth;
    if (width < 576) return 'xs';
    if (width < 768) return 'sm';
    if (width < 992) return 'md';
    if (width < 1200) return 'lg';
    return 'xl';
  }

  /**
   * Track a custom event
   * @param action Event action
   * @param category Event category
   * @param label Optional event label
   * @param value Optional numeric value
   * @param params Additional custom parameters
   */
  trackEvent(
    action: string, 
    category: string, 
    label?: string, 
    value?: number, 
    params?: Record<string, any>
  ): void {
    // Google Analytics Event tracking
    if (window.gtag) {
      // Get current language and page information
      const currentLang = this.translateService.currentLang || 'en';
      const currentPath = this.router.url;
      
      // Get regional language if available
      let region = '';
      try {
        const savedRegion = localStorage.getItem('regional-language') || '';
        if (savedRegion && savedRegion.includes('-')) {
          region = savedRegion.split('-')[1];
        }
      } catch (e) {
        console.warn('Error accessing localStorage for region:', e);
      }
      
      // Merge parameters with standard event parameters
      const eventParams = {
        event_category: category,
        event_label: label,
        value: value,
        language: currentLang,
        region: region || 'default',
        page_path: currentPath,
        page_title: document.title,
        screen_size: this.getScreenSizeCategory(),
        timestamp: new Date().toISOString(),
        ...params
      };
      
      // Send the event with all parameters
      window.gtag('event', action, eventParams);
    }
    
    // Facebook Pixel Custom Event tracking for remarketing
    if (window.fbq) {
      // Create a clean set of parameters for Facebook
      const fbParams = {
        category: category,
        label: label,
        value: value,
        page_path: this.router.url,
        page_title: document.title,
        ...params
      };
      
      // Track as a custom event for Facebook
      window.fbq('trackCustom', action, fbParams);
    }
  }

  /**
   * Track form submissions as both event and conversion
   * @param formName Name of the form
   * @param status Status of submission (success/error)
   */
  trackFormSubmission(formName: string, status: 'success' | 'error'): void {
    // Track standard event for form submissions
    this.trackEvent('form_submission', formName, status);
    
    // If successful submission, also track as a conversion
    if (status === 'success') {
      // Track as a conversion event for GA4
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          'send_to': environment.googleAnalyticsId,
          'event_category': 'form',
          'event_label': formName,
          'value': 1,
          'non_interaction': false,
          // Additional parameters
          'form_name': formName,
          'form_id': formName.replace(/\s+/g, '_').toLowerCase(),
          'form_success': true,
          'conversion_id': `form_${Date.now()}`
        });
        
        console.info(`GA4 Conversion tracked for form: ${formName}`);
      }
      
      // Track as a Lead conversion for Facebook Pixel
      if (window.fbq) {
        window.fbq('track', 'Lead', {
          'content_name': formName,
          'content_category': 'form',
          'value': 1,
          'currency': 'USD'
        });
        
        console.info(`Facebook Lead conversion tracked for form: ${formName}`);
      }
    }
  }
  
  /**
   * Track Facebook specific events for remarketing with consistent segmentation parameters
   * @param eventName The Facebook standard event name
   * @param params Additional parameters for the event
   * @param segmentCategory Segmentation category for audience targeting
   * @param trackType Whether to use standard or custom tracking
   */
  trackFacebookEvent(
    eventName: string, 
    params?: Record<string, any>, 
    segmentCategory: string = 'general',
    trackType: 'standard' | 'custom' = 'standard'
  ): void {
    if (!window.fbq) return;
    
    // Add UTM attribution parameters if available through helper function
    let attributionParams = {};
    if (window['getFbAttributionParams'] && typeof window['getFbAttributionParams'] === 'function') {
      attributionParams = window['getFbAttributionParams']();
    }
    
    // Add standard segmentation parameters - these help with audience creation
    const segmentationParams = {
      segment_category: segmentCategory, // Main category for segmentation (services, products, contact, etc)
      segment_language: this.translateService.currentLang || 'en',
      segment_source: document.referrer || 'direct',
      segment_page: this.router.url || '/',
      segment_date: new Date().toISOString().split('T')[0], // For date-based segmentation
      segment_device: this.detectDeviceType(),
      ...attributionParams, // Add attribution data if available
      ...params // User-provided params take precedence
    };
    
    // Track appropriate Facebook event type
    if (trackType === 'custom') {
      window.fbq('trackCustom', eventName, segmentationParams);
    } else {
      window.fbq('track', eventName, segmentationParams);
    }
    
    console.info(`Facebook ${trackType} event tracked: ${eventName}`, segmentationParams);
  }
  
  /**
   * Creates specific audience segmentation events for remarketing campaigns
   * @param segmentName The segment identifier
   * @param category Main category (products, services, content)
   * @param subcategory Specific subcategory
   * @param value Optional value/score for this segment (1-10)
   */
  createAudienceSegment(
    segmentName: string, 
    category: string, 
    subcategory: string = '', 
    value: number = 1
  ): void {
    if (!window.fbq) return;
    
    // Track custom segment event for audience creation
    window.fbq('trackCustom', 'Segment', {
      segment_name: segmentName,
      segment_category: category,
      segment_subcategory: subcategory,
      segment_value: value,
      segment_language: this.translateService.currentLang || 'en',
      segment_timestamp: new Date().toISOString(),
      segment_page: this.router.url
    });
    
    console.info(`Created audience segment: ${segmentName}/${category}/${subcategory} with value ${value}`);
  }

  /**
   * Track language change
   * @param language New language code
   * @param previousLanguage Previous language code
   */
  trackLanguageChange(language: string, previousLanguage: string): void {
    this.trackEvent('language_change', 'preferences', `${previousLanguage} -> ${language}`);
  }

  /**
   * Track outbound link clicks
   * @param url URL being navigated to
   * @param linkText Text of the link
   * @param category Optional category for the link (default: 'engagement')
   */
  trackOutboundLink(url: string, linkText: string, category: string = 'engagement'): void {
    // Standard outbound tracking
    this.trackEvent('outbound_link', category, `${linkText}: ${url}`);
    
    // If this is a WhatsApp link, also track as a WhatsApp engagement
    if (url.includes('wa.me') || url.includes('whatsapp')) {
      this.trackWhatsAppClick(url, linkText);
    }
    
    // Track as outbound click for Facebook Pixel
    if (window.fbq) {
      // For social links, track as more specific action
      if (url.includes('facebook.com')) {
        window.fbq('trackCustom', 'FacebookLinkClick', { destination: url, link_text: linkText });
      } else if (url.includes('twitter.com') || url.includes('x.com')) {
        window.fbq('trackCustom', 'TwitterLinkClick', { destination: url, link_text: linkText });
      } else if (url.includes('linkedin.com')) {
        window.fbq('trackCustom', 'LinkedInLinkClick', { destination: url, link_text: linkText });
      } else if (url.includes('instagram.com')) {
        window.fbq('trackCustom', 'InstagramLinkClick', { destination: url, link_text: linkText });
      } else {
        // Generic outbound link
        window.fbq('trackCustom', 'OutboundLinkClick', { 
          destination: url,
          link_text: linkText,
          category: category
        });
      }
    }
  }
  
  /**
   * Track WhatsApp clicks specifically
   * @param url The WhatsApp URL being opened
   * @param linkText Text associated with the link
   */
  trackWhatsAppClick(url: string, linkText: string): void {
    // Track as a special WhatsApp interaction event
    this.trackEvent('whatsapp_click', 'contact', linkText, undefined, {
      'whatsapp_url': url,
      'is_priority_conversion': true,
      'contact_method': 'whatsapp',
      'conversion_source': this.router.url || 'direct'
    });
    
    // Also track as a conversion for GA4 goals
    if (window.gtag) {
      window.gtag('event', 'whatsapp_contact', {
        'send_to': environment.googleAnalyticsId,
        'event_category': 'contact',
        'event_label': 'whatsapp_contact',
        'value': 1,
        'non_interaction': false,
        'whatsapp_url': url,
        'conversion_id': `whatsapp_${Date.now()}`
      });
    }
    
    // Track WhatsApp click for Facebook Pixel
    if (window.fbq) {
      // Track as both standard 'Contact' event and custom WhatsApp event
      window.fbq('track', 'Contact', {
        'content_category': 'whatsapp',
        'content_name': linkText
      });
      
      window.fbq('trackCustom', 'WhatsAppContact', {
        'whatsapp_url': url,
        'link_text': linkText,
        'page_path': this.router.url || 'direct'
      });
      
      console.info('Facebook WhatsApp contact event tracked');
    }
  }

  /**
   * Track social sharing
   * @param platform Social platform (facebook, twitter, etc.)
   * @param contentType Type of content shared
   * @param itemId ID of the shared item
   */
  trackSocialShare(platform: string, contentType: string, itemId: string): void {
    this.trackEvent('social_share', platform, `${contentType}:${itemId}`);
  }

  /**
   * Track content downloads
   * @param fileType Type of file downloaded
   * @param fileName Name of the file
   */
  trackDownload(fileType: string, fileName: string): void {
    this.trackEvent('download', fileType, fileName);
  }

  /**
   * Track video interactions
   * @param videoName Name of the video
   * @param action Action taken (play, pause, complete)
   * @param position Position in the video (percentage)
   */
  trackVideoInteraction(videoName: string, action: 'play' | 'pause' | 'complete', position?: number): void {
    this.trackEvent('video_' + action, 'video', videoName, position);
  }

  /**
   * Track search queries
   * @param query Search query
   * @param resultsCount Number of results
   */
  trackSearch(query: string, resultsCount: number): void {
    this.trackEvent('search', 'site_search', query, resultsCount);
  }

  /**
   * Set user ID for cross-device tracking (when logged in)
   * @param userId User identifier (hashed)
   */
  setUserId(userId: string): void {
    if (!window.gtag) return;
    window.gtag('set', 'user_properties', { user_id: userId });
  }
  
  /**
   * Sets user properties for better segmentation
   * @param properties Properties to set
   */
  setUserProperties(properties: {[key: string]: any}): void {
    if (!window.gtag) return;
    window.gtag('set', 'user_properties', properties);
  }
}