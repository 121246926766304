import { AnalyticsService } from './services/analytics.service';
import { Component, OnInit } from '@angular/core';
import { RegionalLanguageService } from './services/regional-language.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private regionalLanguageService: RegionalLanguageService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {
    this.initializeTranslation();
    // Analytics service is automatically initialized
  }

  ngOnInit() {
    // Track manual route changes for better analytics
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Track additional custom metrics on route changes if needed
        this.trackRouteSpecificData(event.urlAfterRedirects);
      });
  }

  /**
   * Track route-specific data for enhanced analytics
   */
  private trackRouteSpecificData(currentRoute: string): void {
    // For specific routes, we can track additional data
    if (currentRoute.includes('/products/')) {
      // Track product page views with additional information
      const productId = currentRoute.split('/').pop();
      if (productId) {
        this.analyticsService.trackEvent(
          'product_view',
          'content',
          productId,
          undefined,
          { product_source: 'direct_navigation' }
        );
      }
    } else if (currentRoute.includes('/services/')) {
      // Track service page views with additional information
      const serviceId = currentRoute.split('/').pop();
      if (serviceId) {
        this.analyticsService.trackEvent(
          'service_view',
          'content',
          serviceId,
          undefined,
          { service_source: 'direct_navigation' }
        );
      }
    } else if (currentRoute === '/contact') {
      // Track when users navigate to contact page
      this.analyticsService.trackEvent(
        'contact_page_visit',
        'engagement',
        'direct_navigation'
      );
    }
  }

  private initializeTranslation() {
    // Set default language
    this.translate.setDefaultLang('en');

    // Get browser language
    const browserLang = this.translate.getBrowserLang();

    // List of supported languages
    const supportedLanguages = ['en', 'es'];

    // Check if browser language is supported
    if (browserLang && supportedLanguages.includes(browserLang)) {
      // Use browser language if supported
      this.translate.use(browserLang);

      // Check if we need to load a regional language
      if (browserLang === 'es') {
        // Try to detect regional variant
        const fullLang = navigator.language;
        if (fullLang && fullLang.startsWith('es-')) {
          // Load the matching regional language if available
          this.regionalLanguageService
            .getAvailableRegions()
            .subscribe((regions) => {
              const matchedRegion = regions.find((r) => r.code === fullLang);
              if (matchedRegion) {
                this.regionalLanguageService
                  .setRegionalLanguage(fullLang)
                  .subscribe(() => {
                    this.regionalLanguageService.saveCurrentRegion(fullLang);
                  });
              }
            });
        }
      }
    } else {
      // Default to English if not supported
      this.translate.use('en');
    }
  }

  /**
   * Changes the application language
   * @param lang Language code (e.g., 'en', 'es')
   */
  switchLanguage(lang: string) {
    const previousLang = this.translate.currentLang;
    this.translate.use(lang);

    // Track language change in analytics
    this.analyticsService.trackLanguageChange(lang, previousLang);
  }

  /**
   * Gets the WhatsApp link with translated message based on current language
   * @param source Optional source parameter to track where the WhatsApp link was clicked
   */
  getWhatsAppLink(source: string = 'default'): string {
    // Phone number
    const phoneNumber = '+5493415970661';

    // Default message (English)
    let message = "Hello, I'm interested in your services";

    // If language is Spanish, use Spanish message
    if (this.translate.currentLang === 'es') {
      message = 'Hola, estoy interesado en sus servicios';
    }

    // Create WhatsApp link
    return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  }

  /**
   * Handles WhatsApp button click with analytics tracking
   * @param event The click event
   */
  onWhatsAppClick(event: MouseEvent): void {
    event.preventDefault();

    // Get the WhatsApp link
    const whatsAppLink = this.getWhatsAppLink('floating_button');

    // Track the WhatsApp click before navigation
    this.analyticsService.trackWhatsAppClick(
      whatsAppLink,
      'Floating WhatsApp Button'
    );

    // Open WhatsApp link in new tab after a small delay to ensure tracking completes
    setTimeout(() => {
      window.open(whatsAppLink, '_blank');
    }, 100);
  }
}
